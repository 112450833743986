/*eslint-disable*/ module.exports = {
  messages: {
    'Any amount, up to {0}': ['Kreditbeträge bis zu ', ['0']],
    'Apply for a {productName}': 'Weiter',
    'Apply now': 'Weiter',
    'Between {0} and {1}': 'N/A',
    'Email already taken - <a href="/login" data-ga-id="step0_email_already_exists__login">log in</a>':
      'Diese E-Mail-Adresse ist bereits registriert',
    'Enter your business email': 'max@musterfirma.de',
    'How much would you like?': 'Wie viel möchten Sie anfragen?',
    'Please enter a valid email address':
      'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    Submitting: 'Ein Moment...',
    'What is your business email address?': 'Wie lautet Ihre E-Mail-Adresse?',
  },
};
